import dayjs from "dayjs"

export default class IQueries {
    constructor() {
    }

    async queryRaw (queryName, params = {}, count=1, offset=0, options={}) {
        throw new Error("Not implemented")
    }

    /** Запрос с множеством результатов (в т.ч. курсоры) */
    async query (queryName, params, count, offset, options={}) {
        let result = await this.queryRaw(queryName, params, count, offset, options)
        
        //Преобразования курсора
        let newResult = {}        
        for (let p of result) {
          if (p.type === 'CURSOR') {
            let newCursor = []
            for (let row of p.value.list) {
              let newRow = {}
              for (let coln in p.value.columns) {
                newRow[p.value.columns[coln][0]] = row[coln]
              }
              newCursor.push(newRow)
            }
            p.value = newCursor
          }
          newResult[p.paramName] = p.value
        }

        return newResult
    }
    
    /** Запрос с единственным результатом (курсором) */
    async select (queryName, params, count, offset, options) {
        let OutParams = await this.query(queryName, params, count, offset, options)
        let result = null
        for (var i in OutParams) {
          result = OutParams[i]
          break
        }
        return result
    }
    
    /** Запрос с таблицой (только первый ряд) */
    async selectRow (queryName, params, options) {
        let values = await this.select(queryName, params, 1, 0, options)
        if (Array.isArray(values) && values.length > 0) {
          return values[0]
        } else {
          return null
        }
    }

}