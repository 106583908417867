/*
import AsyncAuto from 'async-auto'
...
let result = await AsyncAuto([
    {name:'A',func:async()=>( 'A' )}, //run now
    {name:'B',func:async()=>( 'B' )}, //run now parallel
    {name:'C',func:(res)=>( res.A+res.B ),req:['A','B']}, //run after A and B
    {name:'D',func:(res)=>( res.C+res.C ),req:['C']} //run after C
])
*/

export class AsyncAutoTaskError extends Error {
    constructor(task,taskError) {
        super("Task "+task.name+" error: "+taskError.toString())
        this.task = task
        this.taskError = taskError
    }
}

/**
 * Функция выполнится, когда все зависимости выполнятся
 * @callback AsyncAutoCallback
 * @param {array} result - объект с зависимостями
 * @returns {Promise}
 */

/**
 * Задача для AsyncAuto
 * @typedef {{
 *   name: string,
 *   func: AsyncAutoCallback,
 *   req?: string[]
 * }} AsyncAutoTask
 */

/** 
 * Выполнить задачи в с учетом зависимостей
 * @param {AsyncAutoTask[]} argTasks
*/
export default (argTasks) => {
    return new Promise((resolve,reject)=>{
        let rejected = false
        let result = {}

        let tasks = []
        for (let task of argTasks) {
            tasks.push({
                ...task,
                req : task.req?task.req:[],
                status : 0 //0 - wait, 1 - running, 2 - done
            })
        }
        //console.log('tasks',tasks)

        let runTask = (task)=>{
            task.status = 1
            Promise.resolve(task.func(result)).then((taskResult)=>{
                if (rejected) return
                task.status = 2
                result[task.name] = taskResult
                checkTasks()
            }).catch((error)=>{
                if (rejected) return
                rejected = true
                //reject(new AsyncAutoTaskError(task.name,error))
                reject(error)
            })
        }

        let checkTasks = ()=>{
            let allDone = true
            let noRunning = true
            //console.log('checkTasks before',JSON.parse(JSON.stringify(tasks)))
            for (let task of tasks) {
                if (task.status === 0) { //waiting
                    if (task.req.every((req)=>(
                        tasks.find((tsk)=>tsk.name === req).status == 2
                    ))) {
                        runTask(task)
                    }
                }
                if (task.status === 1) { //is running
                    noRunning = false
                }                
                if (task.status !== 2) { //not done
                    allDone = false
                }
            }
            //console.log('checkTasks after',JSON.parse(JSON.stringify(tasks)),allDone,noRunning)
            if (allDone) {
                resolve(result)
            } else if (noRunning) {                
                let waitingTasks = tasks.filter((tsk)=>(tsk.status === 0)).map((tsk)=>(tsk.name))
                reject(new Error('Task(s) '+waitingTasks.join(',')+' will never run'))
            }
            
        }
        
        checkTasks();
    })
}