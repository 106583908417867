import store from "@/core/store/store";
import popups from "@/core/components/popups"
import escapeXml from "@/core/utils/escapeXml"

/** Ошибка из шлюза запросов */
export class GatewayError extends Error {
    constructor(message, name=null, payload=null, logId=null) {
      super(message)
      this.name = name || "GatewayError"
      this.payload = payload
      this.logId = logId
    }
}

/** Детальная ошибка запроса */
export class QueryError extends Error {
    constructor(message, queryName, params, logId) {
        super(message)
        this.queryName = queryName || "unknown"
        this.params = params || {}
        this.logId = logId
    }
}

/** Ошибка пользователя - выводится на экран */
export class UserError extends Error {}
/** Ошибка сессии - надо переавторизоваться */
export class SessionError extends Error {}
/** Ошибка соединения - надо дождаться соединения */
export class ConnectionError extends Error {}

function convertAppservError(err, isDebug) {
    if (err.SymbCode === 'QUERY_USER_ERROR') {
        return new UserError(err.Message)
    }
    if (err.SymbCode === 'INVALID_TOKEN') {
        return new SessionError(err.Message)
    }
    if (isDebug) {
        if (err.SymbCode === 'QUERY_ERROR') {
            return new QueryError(err.Message,err.query,err.queryParams)
            //message = 
        } else {
            return new GatewayError(err.Message)
        }    
    } else {
        return new GatewayError('Произошла внутренняя ошибка в серверной части')
    }
}

export let processError = function(err) {
    console.error(err.name+':', err);
    let isDebug = store.state.config.config.debug;

    //Объект с аппсервера
    if (typeof(err)==='object' && err.SymbCode) {
        //Appserver
        err = convertAppservError(err, isDebug)
    }

    //Просто строка
    if (isDebug && typeof(err)==='string') {
        return 'String:<br>'+err
    }
    
    if (err instanceof UserError) {
        return err.message
    
    } else if (err instanceof SessionError) {
        //Logout
        store.commit('session/clear')
        return null

    } else if (err instanceof QueryError) {
        return 'Query Error '+err.queryName+':<br>'+
            escapeXml(JSON.stringify(err.params||{},null,2))+'<br>'+
            escapeXml(err.message||'');

    //GatewayError
    } else if (err instanceof GatewayError) {
        return 'ServerError: '+err.message

    //Объект JS эксепшн
    } else if (typeof(err)==='object' && typeof(err.message)==='string') {
        let message = err.message
        if (message === "ResizeObserver loop limit exceeded") {
            return null
        }
        if (!isDebug) {
            return null
        }
        return message;
    }

    return 'Unknown Error';
};

export let showError = function(err) {
    let message = processError(err);
    if (!message) return;
    message = message.replace(/(?:\r\n|\r|\n)/g, '<br/>'); //nl2br
    popups.alert(message);
};
