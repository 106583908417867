import dayjs from 'dayjs'
import IQueries from './index'
import GatewayBase from '../gatewayBase'
import { GatewayError, QueryError } from '@/core/utils/errors'

const RxFunc = /^([A-Za-z0-9_]+)\.([A-Za-z0-9_]+)$/

export default class QueriesPgAliasCli extends IQueries {
    
    constructor(gateway) {
        super()
        /** @type GatewayBase */
        this.gateway = gateway
        this.aliases = null        
        this.initCallback = []
        this.starting = false
    }

    async lazyInit() {
      if (!!this.aliases) return
      let resl = new Promise((resolve, reject) => {
        this.initCallback.push(resolve)
      })

      if (!this.starting) {
        this.starting = true        
        import(/* webpackChunkName: "CsWebAliases" */ './csweb-aliases.js').then((aliases)=>{
          this.aliases = aliases.default
          //console.log("!!!",this.aliases)
          for (let cb of this.initCallback) cb()
        })
      }

      return resl
    }
    
    convertDate(result) {
      for (let paramI in result) {
        const p = result[paramI]
        if (p.type !== 'CURSOR') continue
        for (let coln in p.value.columns) {
          const colType = p.value.columns[coln][1]
          if (colType === 'DATE') {
            for (let i in p.value.list) {
              const row = p.value.list[i]
              result[paramI].value.list[i][coln] = 
                  (!!row[coln])? dayjs(row[coln]).format('DD.MM.YYYY HH:mm:ss'): null
            }                
          }
        }
      }      
    }

    async queryRaw (queryName, params = {}, count=1, offset=0, options={}) {
        await this.lazyInit()

        let func = queryName.match(RxFunc)
        if (func) {
          //Вызов функции напрямую
          throw new Error(`Query ${queryName} not acceptable (need PKG.FUN)`)
        }
    
        //Вызов через алиас
        let upName = queryName.toUpperCase()
        let alias = this.aliases.find(s => s.stored === upName)
        if (!alias) throw new Error(`Alias ${upName} not found`)
        
        let fromParams = {}
        for (let i in params) {
          let i2 = i.replace(/$/,'').toUpperCase().trim()
          fromParams[i2] = params[i]
        }
        let funParams = {}
        for (let p of alias.params) {
          if (p.isVar) {
            funParams[p.arg] = fromParams[p.val] || null
          } else {
            funParams[p.arg] = p.val
          }
        }
        
        let result = null
        try {
          result = await this.gateway.serverHttp("POST",'pg-query-pool/exec-func',{
            namespace: alias.pack,
            funcName: alias.name,
            params: funParams,
            count,
            offset
          })

        } catch(e) {
          if (e instanceof QueryError) {
            throw new QueryError(e.message, `${alias.pack}.${alias.name}(${queryName})`, e.params, e.logId)
          } else throw e
        }

        if (!alias.res) {
          console.log('queryAlias', queryName, alias.pack+'.'+alias.name, params, 'no result')
          return []
        }
        result = result.results
        result[0].paramName = alias.res
        if (options.convertDate) this.convertDate(result)
        console.log('queryAlias', queryName, alias.pack+'.'+alias.name, params, count, offset, result)
        return result
    }

}