import IQueries from '../queries'
import GatewayBase from '../gatewayBase'

export class DocListFilters {
    constructor() {
        /** @type {string} */
        this.context = ''
        /** @type {string} */
        this.xml = ''
        /** @type {number[]} */
        this.statusIds = []
        /** @type {object[]} */
        this.columns = []
        /** @type {string} */
        this.classificatorId = null
    }
}

export default class ApiDoclist {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }


    async getTotalRecords(doclistId, filters) {
        let result = await this.carabi.query("CRM_GET_DOCLIST_DATA_SEARCH_CNT9_CS", {
            CARABI_DOCLIST_ID : doclistId, //NUMBER
            STATUS_LIST : this.statusIdsToCarabi(filters.statusIds), //VARCHAR2
            FILTER : filters.context, //VARCHAR2
            FILTER_COLS : this.colFiltersToCarabi(filters.columns), //VARCHAR2
            XML_FILTER : filters.xml, //VARCHAR2
            RES_TREE_VALUE : filters.classificatorId, //VARCHAR2
            FILTER_PRESET : null, //VARCHAR2
            PRESET_ID : null //VARCHAR2
        })
        //console.log("!!!!!!!getTotalRecords",result)
        return result.RESULT
    }

/**
 * 
 * @param {number} doclistId 
 * @param {DocListFilters} filters 
 * @returns 
 */
    async listData(doclistId, filters, order, limit, offset=0) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/list-data", {
                doclistId, filters, order, limit, offset
            })
        } else {
            let dataParams = {
                CARABI_DOCLIST_ID : doclistId, //number
                STATUS_LIST : this.statusIdsToCarabi(filters.statusIds), //number
                FILTER : filters.context, //varchar
                FILTER_COLS : this.colFiltersToCarabi(filters.columns), //varchar
                FILTER_PRESET : null, //varchar
                XML_FILTER : filters.xml,
                PRESET_ID: null,
                ORDER_BY : this.orderToCarabi(order), //varchar
                RES_TREE_VALUE : filters.classificatorId //varchar
            }
            return await this.carabi.query(
                "CRM_GET_DOCLIST_DATA_SEARCH9_CS",
                dataParams,          
                limit,
                offset,
                { convertDate:true }
            )
        }
    }

    /** Данные для таблицы (дерево) */
    async listTreeData(doclistId, parentId, filters, order) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/list-tree-data", {
                doclistId, parentId, filters, order
            })
        } else {
            let dataParams = {
                "carabi_doclist_id" : doclistId, //number
                "parent_id" : parentId,
                "status_id" : this.statusIdsToCarabi(filters.statusIds), //number
                "xml_filter" : filters.xml, //clob
                "filter" : filters.context, //varchar
                "filter_cols" : this.colFiltersToCarabi(filters.columns), //varchar
                "order_by" : this.orderToCarabi(order) //varchar
            };
            return await this.carabi.select("BR_CARABI_DOCLIST_DATA_TREE_SEARCH_CS",dataParams,10000)
        }
    }

    async getRow(doclistId, documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/get-row", {
                doclistId, documentId
            })
        } else {
            return await this.carabi.selectRow( "CRM_GET_DOCUMENT_ROWS_CS", {
                DOC_LIST : documentId //varchar
            })           
        }
    }

    async getInfo(doclistId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/get-info", {
                doclistId
            })
        } else {
            return await this.carabi.selectRow('BR_CARABI_DOCLIST_INFO_CS', {
                CARABI_DOCLIST_ID: doclistId
            })
        }
    }

    async listStages(docListId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/list-stages", {
                docListId
            })
        } else {
            return await this.carabi.select('GET_BP_STAGES_BY_DOCLIST_CS', {
                CARABI_DOCLIST_ID: docListId
            }, 1000)
        }
    }

    async listStructure(docListId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/list-structure", {
                docListId
            })
        } else {
            return await this.carabi.select('CRM_DOCLIST_STRUC', {
                CARABI_DOCLIST_ID: docListId
            }, 1000)
        }
    }

    async listActions(docListId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/list-actions", {
                docListId
            })
        } else {
            return await this.carabi.select('CRM_GET_TABLE_ACTIONS_CS', {
                carabi_doclist_id: docListId
            }, 1000)
        }
    }

    async listClassificatorTree(docListId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/list-classificator-tree", {
                docListId
            })
        } else {
            return await this.carabi.select( "CRM_GET_DOCLIST_TREE_CS", {
                CARABI_DOCLIST_ID : docListId
            }, 1000)
        }
    }

/**
 * 
 * @param {string} doclistId 
 * @param {object[]} order 
 */
    async setUserOrder(doclistId, order) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/set-user-order", {
                doclistId, order
            })
        } else {
            //{fieldName: 'DOC_STATUS_DESCR', direction: 'up'}
            let fieldName = ''
            let direction = ''
            if (order.length > 0) {
                fieldName = order[0].dataIndx
                direction = order[0].dir
            }
            await this.carabi.query('SET_USER_COLUMN_ORDERBY',{
                "column_name" : fieldName, //varchar
                "orderby" : direction, //varchar
                "carabi_doclist_id" : doclistId //number
            })
        }
    }

    async setUserColumnProps(docListId, colName, props) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/set-user-column-props", {
                docListId, colName, props
            })
        } else {
            await this.carabi.query('SET_USER_COLUMN_PROP',{
                "carabi_doclist_id" : docListId,
                "column_name" : colName,
                "width" : (props.WIDTH)?props.WIDTH:0,
                "visible" : (props.VISIBLE)?1:0,
            })
        }
    }

    async setUserColumnsOrder(docListId, colName, nextColumn) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "doclists/set-user-columns-order", {
                colName, nextColumn, docListId
            })
        } else {
            await this.carabi.query('CRM_SET_USER_COLUMN_SHOW_ORDER',{
                "COLUMN_NAME" : colName, //varchar
                "BEFORE_COLUMN_NAME" : nextColumn, //varchar
                "CARABI_DOCLIST_ID" : docListId //number
            })
        }
    }

    //Utils
    statusIdsToCarabi(statusIds) {
        if (!statusIds || statusIds.length === 0) {
            return null
        }
        return statusIds.join(",")
    }
    
    colFiltersToCarabi(colFilters) {
      if (!colFilters) return '';
      return JSON.stringify({"filter": colFilters });
    }
    
    orderToCarabi(order) {
        if (!order) return '';
        let sort_str = '';
        for (let sort of order) {
          if (sort_str != '') {
            sort_str += ',';
          }
          sort_str += sort.dataIndx+ ' '+((sort.dir == 'up')?'ASC':'DESC');
        }
        return sort_str;
    }
}